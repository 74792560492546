export default () => ({
  api: {
    servers: {
      default: $env('server'),
    },
    then: (response) => {
      let min = 0
      if (localStorage.getItem('lastActivity')) {
        min = Math.floor((new Date().getTime() - new Date(localStorage.getItem('lastActivity')).getTime()) / 60000)
      }
      if (min < 5) {
        localStorage.setItem('lastActivity', new Date().toString())
      } else {
        $app.router.router().push({ name: 'login', })
      }
      
      if (!response.data.content) {
        response.data.content = response.data.data
      }
      if (response.data.message) {
        $app.ui.notify(response.data.message, 'success')
      }
      return response
    },
    catch: (error) => {
      if (error.response?.status === 401) {
        $app.auth.logout()
      } else if (error.response?.status === 422) {
        // const message = error.response?.data.message
        let data = error.response?.data.data
        let errors = ''
        if (Object.keys(data).length > 0) {
          $n.each(data, (item) => {
            errors += item[0]
          })
        } else {
          data = error.response?.data.message
          errors = data
        }
        // $app.ui.notify(errors, 'danger')
      } else {
        const message = error.response?.data.message || 'Сервер недоступен'
        // $app.ui.notify(message, 'danger')
      }
      throw error
    },
  },
  router: {
    config: {
      base: $env('baseUrl'),
      mode: 'history',
    },
  },
  store: {
    saveDepth: 2,
  },
  auth: {
    loginRoute: 'login',
    authRoute: 'mobile.index',
    apiAuth: (token) => {
      $app.api.config({
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
    },
  },
  date: {
    formats: {
      date: 'DD.MM.YYYY',
      time: 'HH:mm',
      datetime: 'DD.MM.YYYY HH:mm',
    },
    default: 'datetime',
  },
  form: {
    input: (self, name, type, form) => {
      if (type === 'select') {
        return {
          name,
          value: self.$form.get(name, form),
          input: (value) => self.$form.set(name, value, form),
          danger: self.$form.errors(name, form),
          text: self.$form.editable(form) === false,
        }
      }
      return {
        name,
        value: self.$form.get(name, form),
        input: (value) => self.$form.set(name, value, form),
        danger: self.$form.errors(name, form),
        text: self.$form.editable(form) === false,
      }
    },
    validations: {
      required(value) {
        return value.length > 0
      },
      biggerThanMin(value) {
        return value >= 1000
      },
      email(value) {
        return value.indexOf('@') !== -1
      },
      phone(value) {
        return value.indexOf('+') !== -1
      },
    },
    messages: {
      required: 'Поле обязательно для заполнения',
      biggerThanMin: 'Минимальная сумма снятия 1000',
      email: 'Поле должно быть email адресом',
      phone: 'Поле должно быть корректным телефонным номером',
      between: (name, params, value) => `Поле должно быть от ${params[0]} до ${params[1]}`,
    },
  },
  secure: {
    whiteList: { p: [], br: [], },
  },
  ui: {
    duration: {
      info: 3000,
      danger: 5000,
      success: 3000,
    },
  },
})
