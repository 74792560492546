import each from 'lodash/each'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import reject from 'lodash/reject'
import merge from 'lodash/merge'
import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'
import trim from 'lodash/trim'
import pull from 'lodash/pull'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import assign from 'lodash/assign'
import size from 'lodash/size'
import invert from 'lodash/invert'
import values from 'lodash/values'
import reverse from 'lodash/reverse'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import defer from 'lodash/defer'
import delay from 'lodash/delay'
import random from 'lodash/random'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import shuffle from 'lodash/shuffle'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isBoolean from 'lodash/isBoolean'


const setInRange = (value, a, b) => {
  const min = a < b ? a : b
  const max = a > b ? a : b
  
  if (value < min) return min
  if (value > max) return max
  
  return value
}

/**
 * @param {Array|Object} container
 * @param {Function} callback
 * @param {string} childrenName
 * @param {Function} parentData
 */
const eachDeep = (container, callback, childrenName = 'children', parentData = undefined) => {
  each(container, (item, key) => {
    let _parentData = parentData
    const data = (d) => {
      if (d === undefined) return _parentData
      _parentData = d
    }
    callback(item, key, data)
    const children = item[childrenName]
    if (isArray(children) || isObject(children)) {
      eachDeep(children, callback, childrenName, _parentData)
    }
  })
}

const mapDeep = (container, childrenName, callback, deep = 0) => {
  return map(container, (cont) => {
    cont = clone(cont)
    let children = cont[childrenName]
    if (isArray(children) || isObject(children)) {
      children = mapDeep(children, childrenName, callback, deep)
    }
    if (children !== undefined) {
      cont[childrenName] = children
    }
    return callback(cont)
  })
}

/**
 * @param {Array|Object} container
 * @param {Function} callback
 * @param {*} accumulator
 * @param {string} childrenName
 * @param {Function} parentData
 * @return {*}
 */
const reduceDeep = (container, callback, accumulator, childrenName = 'children', parentData = undefined) => {
  return reduce(container, (result, item, key) => {
    let _parentData = parentData
    const data = (d) => {
      if (d === undefined) return _parentData
      _parentData = d
    }
    result = callback(result, item, key, data)
    const children = item[childrenName]
    if (isArray(children) || isObject(children)) {
      result = reduceDeep(children, callback, result, childrenName, _parentData)
    }
    return result
  }, accumulator)
}

const promiseObjects = (array, key = 'promise') => {
  const promises = []
  
  array.forEach((object) => promises.push(object[key]))
  
  return Promise.all(promises).then((responses) => {
    return responses.reduce((result, response, i) => {
      result.push({
        ...array[i],
        response,
      })
      return result
    }, [])
  })
}

const t = (key) => {
  return $n.get($app.store.getter('app.translates'), key, '')
}


const formatNumber = (number) => {
  return new Intl.NumberFormat('ru-RU').format(Math.round(number))
}


let sortedField = ''
const sort = (arr, field) => {
  let type
  if (sortedField === field) {
    type = 'asc'
    sortedField = ''
  } else {
    sortedField = field
    type = 'desc'
  }
  const k = type === 'asc' ? 1 : -1
  return arr.sort((a, b) => {
    const typeA = typeof a[field]
    const typeB = typeof b[field]
    if (Number(a[field]) || Number(b[field])) {
      return k*a[field] + -k*b[field]
    }
    if (typeA === 'string' && typeB === 'string') {
      if (type === 'asc') {
        return a[field].localeCompare(b[field], 'ru')
      } else {
        return b[field].localeCompare(a[field], 'ru')
      }
    }
  })
}

const notifyAvailable = () => {
  return Capacitor.isPluginAvailable('PushNotifications')
}

const isPromise = (obj) => {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function'
}
const phoneFormat = (number) => {
  const cleaned = ('' + number).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d+)$/)
  if (match) {
    return '+7 (' + match[2] + ') ' + match[3] + '-' + match[4] + '-' + match[5]
  }
  return number
}
const iinFormat = (number) => {
  number = number.replace(/[^0-9]/g, '')
  if (number.length <12) {
    return number
  } else {
    return number.substr(0, number.length-1)
  }
}

const openWhatsapp = () => {
  return window.location.href = 'https://wa.me/77073619398?text=Здравствуйте!%0AУ меня есть вопрос.'
}
const openSite = () => {
  window.location.href = 'https://prosperpay.kz/'
}
const openInstagram = () => {
  window.location.href = 'https://www.instagram.com/'
}
const openTelegram = () => {
  window.location.href = 'https://t.me/ProsperPay'
}


import halykIcon from '../assets/bank/halyk-icon.svg'
import KaspiIcon from '../assets/bank/kaspi-icon.svg'
import AltynIcon from '../assets/bank/altyn-bank-icon 1.svg'
import EurasianIcon from '../assets/bank/eurasian-bank.svg'
import RBKIcon from '../assets/bank/rbk.svg'
import SimplyIcon from '../assets/bank/simply.svg'
import CenterIcon from '../assets/bank/center-credit.svg'
import VTBIcon from '../assets/bank/vtb.svg'
import visaIcon from '../assets/bank/visa-icon.svg'
import mastercardIcon from '../assets/bank/mastercard-icon.svg'
import defaultIconIssuer from '../assets/bank/card.svg'
import defaultBankIcon from '../assets/bank/bank.svg'

const getBankIcon = (bankName) => {
  switch (bankName) {
  case 'KAZKOMMERTSBANK': {
    return halykIcon
  }
  case 'JSB KAZKOMMERTSBANK': {
    return halykIcon
  }
  case 'VTB BANK (KAZAKHSTAN) SUBSIDIARY JOINT STOCK COMPANY': {
    return AltynIcon
  }
  case 'JSC EURASIAN BANK': {
    return EurasianIcon
  }
  case 'BANK CENTER CREDIT': {
    return CenterIcon
  }
  case 'Bank RBK': {
    return RBKIcon
  }
  case 'Simply': {
    return SimplyIcon
  }
  case 'PJSC VTB BANK': {
    return VTBIcon
  }
  default: {
    if (bankName === 'US') {
      return KaspiIcon
    } else {
      return defaultBankIcon
    }
  }
  }
}
const getIssuerIcon = (issuerName) => {
  switch (issuerName) {
  case 'visa': {
    return visaIcon
  }
  case 'mastercard': {
    return mastercardIcon
  }
  default: {
    return defaultIconIssuer
  }
  }
}

export default () => ({
  each,
  map,
  reduce,
  find,
  filter,
  reject,
  merge,
  get,
  set,
  unset,
  trim,
  pull,
  includes,
  concat,
  assign,
  size,
  invert,
  values,
  reverse,
  clone,
  cloneDeep,
  defer,
  delay,
  random,
  sortBy,
  shuffle,
  debounce,
  throttle,
  isEqual,
  isObject,
  isArray,
  isFunction,
  isString,
  isBoolean,
  
  setInRange,
  mapDeep,
  reduceDeep,
  eachDeep,
  isPromise,
  promiseObjects,
  phoneFormat,
  openWhatsapp,
  openSite,
  openTelegram,
  openInstagram,
  getBankIcon,
  getIssuerIcon,
  iinFormat,
  t,
  formatNumber,
  notifyAvailable,
  sort,
})
