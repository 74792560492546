<template>
  <div class="c-phone-verify">
    <c-go-back backroute="mobile.profile.security" @click="closeCode" />
    <c-frame v-if="show" bacroute="mobile.payment" :url="frameUrl" :show.sync="show" />
    <div v-if="step === 0" class="content">
      <n-loader :loading="$var('load')" />
      <div class="text">
        <h1><t name="1.4.1" /></h1>
        <p><t name="1.4.2" /></p>
      </div>
      <c-code-input :received="code" :code.sync="code" />
      <div class="lower-text">
        <p :class="{danger: !!$var('message')}">{{ $var('message') || $app.secure.clean($n.t('1.4.3')) }}</p>
        <p v-if="timer > 0"><t name="1.4.7" /> {{ timer }}</p>
        <p v-else @click="sendCode"><t name="1.4.4" /></p>
      </div>
      <div class="button-place">
        <n-button v-if="!hideButton" type="submit" class="user-default-button" :disabled="isDisabled" @click="close()"><t name="1.4.5" /></n-button>
      </div>
    </div>
  </div>
</template>

<script>
import imgPhoneVerify from 'assets/phone-verify.svg'
export default {
  name: 'CPhoneVerify',
  props: {
    amount: {
      required: true,
    },
    toCardsId: {
      required: true,
    },
  },
  data() {
    return {
      code: '',
      step: 0,
      isDisabled: true,
      payData: [],
      user: $app.auth.user(),
      showAfterPay: false,
      sum: 0,
      isSuccess: false,
      hideButton: false,
      show: false,
      frameUrl: '',
      interval: setInterval(this.decTimer, 1000),
      timer: 30,

      imgPhoneVerify,
    }
  },
  watch: {
    code() {
      if (this.code.length === 4) {
        this.isDisabled = false
      }
    },
    show() {
      if (!this.show) {
        $app.store.action('app.updateUser').finally(() => {
          this.$router.push({ name: 'mobile.index', })
        })
      }
    },
  },
  beforeDestroy() {
    this.removeListener()
  },
  methods: {
    setCode() {
      document.addEventListener('onSMSArrive', (args) => {
        this.code = args.message.substr(0, 4)
      })
    },
    removeListener() {
      document.removeEventListener('onSMSArrive', (args) => {})
    },
    listenSms() {
      this.setCode()
      cordova.plugins.SMSRetriever.startWatch((r) => {}, (e) => {})
    },
    closeCode() {
      this.$emit('update:show', false)
    },
    decTimer() {
      this.timer--
      if (this.timer <= 0) {
        clearInterval(this.interval)
      }
    },
    sendCode() {
      this.$var('load', true)
      $api.auth.sendCode(this.user.iin, this.user.phone).then((response) => {
        if (Capacitor.getPlatform() === 'android') {
          this.listenSms()
        }
      }).finally(() => {
        this.$var('load', false)
      })
    },
    close() {
      if (this.code.length === 4) {
        this.$var('load', true)
        $api.users.checkCode($app.auth.user().id, { code: this.code, }).then((response) => {
          if (response.data.content.isAvailable) {
            this.$var('load', true)
            this.hideButton = true
            if (this.user.company.payoutProvider === 'pitech') {
              $api.payment.doPay(this.user.id, {
                amount: this.amount,
                toCardsId: this.toCardsId+'',
              }).then((response) => {
                this.frameUrl = response.data.content.paymentUrl
                this.show = true
                this.step=1
              }).catch(() => {}).finally(() => {
                this.$var('load', false)
              }) 
            } else {
              this.$var('load', true)
              this.hideButton = true
              $api.payment.wooopayDoPay({
                amount: this.amount,
              }).then((response) => {
                if (response.data.content.frame_url) {
                  this.frameUrl = response.data.content.frame_url
                  this.show = true
                  this.step=1 
                } else {
                  alert('Ошибка проведения платежа')
                  this.$router.push({ name: 'mobile.index', })
                }
              }).catch(() => {}).finally(() => {
                this.$var('load', false)
              })
            }
          }
        }).catch((response) => {
          this.$var('message', $app.secure.clean($n.t(response.response.data.message)))
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-phone-verify {
  z-index: 11;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  ::v-deep{
    .c-go-back{
      position: absolute;
      top: calc(1px + env(safe-area-inset-top));
      left: 25px;
    }
  }

  .content {
    .text {
      h1 {
        font-size: 24px;
        color: #1c1c1c;
        font-weight: 500;
        text-align: center;
        margin: 0 0 5px 0;
      }
      p {
        margin: 0 auto 30px;
        text-align: center;
        width: 80%;
      }
    }
    .lower-text {
      p {
        margin: 0;
        font-size: 14px;
        text-align: center;
        &:last-child {
          color: #A91CD2;
          text-decoration: underline;
        }
        &:first-child {
          font-size: 12px;
          color: #9CA3AF;
          &.danger {
            color: red;
            font-size: 16px;
          }
        }
      }
    }
    .button-place {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      .user-default-button {
        width: 90%;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
</style>
