/**
 * 
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
    loginPin: (userId, data) => $app.api.post([ 'auth/login/users*/pin', userId, ]).data(data),
    register: (iin, phone, data) => $app.api.post([ 'auth/register**', iin, phone, ]).data(data),
    recover: (iin, data) => $app.api.post([ 'auth/recover*', iin, ]).data(data),
    info: () => $app.api.get([ 'auth/info', ]),
    checkData: (data) => $app.api.post([ 'auth/check-data', ]).data(data),
    sendCode: (iin, phone) => $app.api.post([ 'sms/send-code**', iin, phone, ]),
    checkCode: (iin, data) => $app.api.post([ 'sms/check-code*', iin, ]).data(data),
    deleteAcc: (id) => $app.api.patch([ 'auth/delete/users*', id, ]),
    checkIin: (iin) => $app.api.post([ 'iin-check*', iin, ]),
  }

  lang = {
    locales: (lang) => $app.api.get([ 'locales', ]),
    items: () => $app.api.get([ 'translations/content', ]),
  }
  
  companies = {
    get: (id) => $app.api.get([ 'companies*', id, ]),
    excel: (id, data) => $app.api.post([ 'companies*/excel', id, ]).data(data),
    edit: (id, data) => $app.api.patch([ 'companies*', id, ]).data(data),
    pitech: (id, data) => $app.api.post([ 'companies*/pitech', id, ]).data(data),
    pitechExcel: (id, data) => $app.api.post([ 'companies*/pitech/excel', id, ]).data(data),

  }
  
  users = {
    get: (id) => $app.api.get([ 'users*', id, ]),
    getCompany: (id) => $app.api.get([ 'users-companies*', id, ]),
    getMyCompanies: (id) => $app.api.get([ 'users*/companies', id, ]),
    getByCompany: (companyId) => $app.api.get([ 'companies*/users*', companyId, ]).filter({ status: 'not:fired', }),
    edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
    editUserCompany: (id, data) => $app.api.patch([ 'userCompany*', id, ]).data(data),
    editPassword: (id, data) => $app.api.patch([ 'users*/password', id, ]).data(data),
    checkPassword: (id, data) => $app.api.patch([ 'users*/password-check', id, ]).data(data),
    sendCode: (id) => $app.api.post([ 'users*/send-code', id, ]),
    checkCode: (id, data) => $app.api.post([ 'users*/check-code', id, ]).data(data),
    editPin: (id, data) => $app.api.patch([ 'users*/edit-pin', id, ]).data(data),
    editLimit: (id, data) => $app.api.patch([ 'users*/limit', id, ]).data(data),
  }

  transactions = {
    check: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions/check', companyId, userId, ]).data(data),
    create: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions', companyId, userId, ]).data(data),
    getList: (companyId, userId, request = '') => $app.api.get([ 'companies*/users*/transactions'+request, companyId, userId, ]),
  }
  
  payment = {
    doPay: (userId, data) => $app.api.post([ 'payment/do*', userId, ]).data(data),
    wooopayDoPay: (data) => $app.api.post([ 'payment/woopay/do', ]).data(data),
    saveCard: (userId) => $app.api.post([ 'payment/save-card', userId, ]),
    getCards: (userId) => $app.api.post([ 'payment/get-cards', userId, ]),
    deleteCard: (cardsId) => $app.api.post([ 'payment/delete-card*', cardsId, ]),
  }
  
  contacts = {
    get: () => $app.api.get([ 'contacts', ]),
  }
  
  application = {
    getByUser: (userId) => $app.api.get([ 'applications/users*', userId, ]),
    create: (data) => $app.api.post([ 'applications  ', ]).data(data),
  }
  commission = {
    getMin: () => $app.api.get([ 'commission/minimal', ]),
  }
  faqs = {
    get: () => $app.api.get([ 'faqs', ]),
  }

  notifications = {
    get: (userId) => $app.api.get([ 'notifications/users*', userId, ]),
    getUnreadCount: (userId) => $app.api.get([ 'notifications/users*/unread-count', userId, ]),
    delete: (id) => $app.api.delete([ 'notifications*', id, ]),
  }

  fcmPush = {
    save: (data) => $app.api.post('fcm-push/save').data(data),
    deleteToken: (data) => $app.api.delete('fcm-push/delete').data(data),
  }
  branches = {
    get: (companyId, id) => $app.api.get([ 'companies*/branches*', companyId, id, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/branches', companyId, ]).data(data),
    edit: (companyId, id, data) => $app.api.patch([ 'companies*/branches*', companyId, id, ]).data(data),
    delete: (companyId, id) => $app.api.delete([ 'companies*/branches*', companyId, id, ]),
  }
}
