export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '', name: 'login', icon: 'genderless', component: () => import('pages/auth/Index'), },
      { path: '/recover', name: 'recover', icon: 'genderless', component: () => import('pages/auth/recover-password/Index'), },
      { path: '/recover/finish', name: 'recover.finish', icon: 'genderless', component: () => import('pages/auth/recover-password/Recover'), },
      { path: '/register', name: 'register', icon: 'genderless', component: () => import('pages/auth/registration/Index'), },
      { path: '/register/finish', name: 'register.finish', icon: 'genderless', component: () => import('pages/auth/registration/Finish'), },
      { path: '/privacy-policy', name: 'privacyPolicy', icon: 'genderless', component: () => import('pages/ agreements/privacy-policy/Index'), },
      { path: '/terms-of-use', name: 'termsOfUse', icon: 'genderless', component: () => import('pages/ agreements/terms-of-use/Index'), },
      { path: '/register/finish', name: 'register.finish', icon: 'genderless', component: () => import('pages/auth/registration/Finish'), },
    ], },
    { path: '', component: () => import('layouts/admin/Index'), children: [
      { path: '/desktop/main', name: 'desktop.index', icon: 'genderless', component: () => import('pages/desktop/index/Index'), },
      { path: '/desktop/user/:id', name: 'desktop.user', icon: 'genderless', component: () => import('pages/desktop/user/Index'), },
      { path: '/desktop/users', name: 'desktop.users', icon: 'genderless', component: () => import('pages/desktop/users/Index'), },
      { path: '/desktop/profile', name: 'desktop.profile', icon: 'genderless', component: () => import('pages/desktop/profile/Index'), },
      { path: '/desktop/support', name: 'desktop.support', icon: 'genderless', component: () => import('pages/desktop/support/Index'), },
    ], },
    { path: '', component: () => import('layouts/user/Index'), children: [
      { path: '/mobile/transactions', name: 'mobile.transactions', left: '', right: 'mobile.index', icon: 'genderless', component: () => import('pages/mobile/transactions/Index'), },
      { path: '/mobile/main', name: 'mobile.index', left: 'mobile.transactions', right: 'mobile.profile', icon: 'genderless', component: () => import('pages/mobile/index/Index'), },
      { path: '/mobile/profile', name: 'mobile.profile', left: 'mobile.index', right: '', icon: 'genderless', component: () => import('pages/mobile/profile/Index'), },
      { path: '/mobile/profile/my-data', name: 'mobile.profile.myData', left: 'mobile.profile', right: '', icon: 'genderless', component: () => import('pages/mobile/my-data/Index'), },
      { path: '/mobile/profile/my-cards', name: 'mobile.profile.myCards', left: 'mobile.profile', right: '', icon: 'genderless', component: () => import('pages/mobile/my-cards/Index'), },
      { path: '/mobile/payment/after', name: 'mobile.payment.after', left: 'mobile.profile', right: 'mobile.index', icon: 'genderless', component: () => import('pages/mobile/after-pay/Index'), },
      { path: '/mobile/profile/support', name: 'mobile.profile.support', left: 'mobile.profile', right: '', icon: 'genderless', component: () => import('pages/mobile/support/Index'), },
      { path: '/mobile/profile/security', name: 'mobile.profile.security', left: 'mobile.profile', right: '', icon: 'genderless', component: () => import('pages/mobile/security/Index'), },
      { path: '/mobile/profile/support/faqs', name: 'mobile.profile.support.faqs', left: 'mobile.profile', right: '', icon: 'genderless', component: () => import('pages/mobile/faqs/Index'), },
      { path: '/mobile/profile/notifications', name: 'mobile.profile.notifications', left: 'mobile.profile', right: '', icon: 'genderless', component: () => import('pages/mobile/notifications/Index'), },
      { path: '/mobile/profile/contacts', name: 'mobile.profile.contacts', left: 'mobile.profile', right: '', icon: 'genderless', component: () => import('pages/mobile/contacts/Index'), },
      { path: '/mobile/profile/support/applications', name: 'mobile.profile.support.applications', icon: 'genderless', component: () => import('pages/mobile/applications/Index'), },
    ], },
    { path: '', component: () => import('layouts/payment/Index'), children: [
      { path: '/mobile/payment', name: 'mobile.payment', icon: 'genderless', component: () => import('pages/mobile/payment/Index'), },
    ], },
  ], },
]
