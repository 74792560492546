<template>
  <div class="c-password-reset">
    <n-loader :loading="$var('load')"></n-loader>
    <c-verify-code v-if="show" :show.sync="show" :state.sync="isSuccess" :iin="user.iin" phone="recover" />
    <div class="container">
      <h3><t name="1.11.4" /></h3>
      <div class="input-place">
        <div class="item">
          <n-input
            :danger="$var('m1')" name="oldPass" class="password-input" :placeholder="$app.secure.clean($n.t('1.11.5'))"
            type="password" v-bind="$form.input('oldPassword')" />
          <n-button class="eye" flat round @click="changeInputType('oldPass')">
            <img :src="$var('oldPasshide') ? imgEyeHide : imgEyeShow" alt="">
          </n-button>
        </div>
        <div class="item">
          <n-input
            :danger="$var('m2')" name="tryOldPass" class="password-input" :placeholder="$app.secure.clean($n.t('1.11.6'))"
            type="password" v-bind="$form.input('newPassword')" />
          <n-button class="eye" flat round @click="changeInputType('tryOldPass')">
            <img :src="$var('tryOldPasshide') ? imgEyeHide : imgEyeShow" alt="">
          </n-button>
        </div>
        <div class="item">
          <n-input
            :danger="$var('m3')" name="newPass" class="password-input" :placeholder="$app.secure.clean($n.t('1.11.7'))"
            type="password" v-bind="$form.input('confirmPassword')" />
          <n-button class="eye" flat round @click="changeInputType('newPass')">
            <img :src="$var('newPasshide') ? imgEyeHide : imgEyeShow" alt="">
          </n-button>
        </div>
      </div>
    </div>
    <div>
      <div class="button-place first">
        <n-button class="user-default-button" @click="sendCode"><t name="1.11.8" /></n-button>
      </div>
      <div class="button-place">
        <n-button class="gray user-default-button" @click="closeForm"><t name="1.11.9" /></n-button>
      </div>
    </div>
  </div>
</template>

<script>
import imgBackrow from 'assets/backrow.svg'
import imgClose from 'assets/close-icon.svg'
import imgEyeShow from 'assets/eye-icon-show.svg'
import imgEyeHide from 'assets/eye-icon-hide.svg'

export default {
  name: 'CPasswordReset',
  data() {
    return {
      isBtnDisabled: true,
      user: $app.auth.user(),
      show: false,
      isSuccess: false,
      isHide: true,
      imgBackrow,
      imgClose,
      imgEyeHide,
      imgEyeShow,
    }
  },
  watch: {
    isSuccess() {
      if (this.isSuccess) {
        this.reset()
        this.isSuccess = false
      }
    },
  },
  created() {
    this.$form.init({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    this.$form.rules({
      oldPassword: [ 'required', ],
      newPassword: [ 'required', ],
      confirmPassword: [ 'required', ],
    })
    this.$var('newPasshide', true)
    this.$var('tryOldPasshide', true)
    this.$var('oldPasshide', true)
  },
  methods: {
    changeInputType(name) {
      const input = document.getElementById(name)
      if (input) {
        if (input.type === 'text') {
          input.type = 'password'
          this.$var(name + 'hide', true)
        } else {
          input.type = 'text'
          this.$var(name + 'hide', false)
        }
        input.focus()
      }
    },
    reset() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.users.editPassword($app.auth.user().id, this.$form.get()).then(() => {
          $app.auth.logout()
        }).catch((response) => {
          this.$var('m1', $app.secure.clean($n.t(response.response.data.data.oldPassword)) || '')
          this.$var('m2', $app.secure.clean($n.t(response.response.data.data.newPassword)) || '')
          this.$var('m3', $app.secure.clean($n.t(response.response.data.data.confirmPassword)) || '')
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        if (!this.$form.get('oldPassword')) {
          this.$var('m1', $app.secure.clean($n.t('3.20')))
        }
        if (!this.$form.get('newPassword')) {
          this.$var('m2', $app.secure.clean($n.t('3.20')))
        }
        if (!this.$form.get('confirmPassword')) {
          this.$var('m3', $app.secure.clean($n.t('3.20')))
        }
      }
    },
    sendCode() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.users.checkPassword($app.auth.user().id, this.$form.get()).then(() => {
          this.show = true
        }).catch((response) => {
          this.$var('m1', $app.secure.clean($n.t(response.response.data.data.oldPassword)) || '')
          this.$var('m2', $app.secure.clean($n.t(response.response.data.data.newPassword)) || '')
          this.$var('m3', $app.secure.clean($n.t(response.response.data.data.confirmPassword)) || '')
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        if (!this.$form.get('oldPassword')) {
          this.$var('m1', $app.secure.clean($n.t('3.20')))
        }
        if (!this.$form.get('newPassword')) {
          this.$var('m2', $app.secure.clean($n.t('3.20')))
        }
        if (!this.$form.get('confirmPassword')) {
          this.$var('m3', $app.secure.clean($n.t('3.20')))
        }
      }
    },
    closeForm() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-password-reset {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1001;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  padding-top: calc(env(safe-area-inset-top) + 25px);

  .item {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid rgba(239, 239, 239, 0.76);
    min-height: 67px;
    padding: 0 8px;
    display: flex;
    margin-bottom: 22px;
    align-items: center;
  }

  h3 {
    color: black;
    margin: 0;
    text-align: center;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  ::v-deep .n-button.default:not([disabled]):hover {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .input-place {
    margin-top: 50px;

    .password-input {
      .eye {
        position: absolute;
        right: 12px;
        bottom: 10px;
      }


      ::v-deep .n-content {
        border: none;
      }
    }
  }

  .button-place {
    width: 90%;
    display: block;
    margin: 0 auto 15px;

    &:first-child {
      margin-top: 35px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .user-default-button {
      width: 90%;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
