<template>
  <div class="c-go-back" @click="$emit('click')">
    <n-link v-if="!values" :to="{name: backroute}">
      <div class="wrap">
        <div class="back-row">
          <img :src="imgBackrow" alt="">
        </div>
        <span><t name="1.4.6" /></span>
      </div>
    </n-link>
    <n-link v-else :to="{name: backroute, params: {values: values}}">
      <div class="wrap">
        <div class="back-row">
          <img :src="imgBackrow" alt="">
        </div>
        <span><t name="1.4.6" /></span>
      </div>
    </n-link>
    <h1 class="title">{{ title }}</h1>
  </div>
</template>

<script>
import imgBackrow from 'assets/arrow-right.svg'

export default {
  name: 'CGoBack',
  props: {
    backroute: {
      type: String,
      required: true,
    },
    values: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imgBackrow,
    }
  },
}
</script>

<style lang="scss" scoped>
.c-go-back {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: calc(env(safe-area-inset-top) + 10px);
  left: 15px;
  right: 15px;
  background: var(--body-bg);
  height: 30px;
  z-index: 6;
  &::after{
    content: '';
    position: absolute;
    background: var(--body-bg);
    left: -15px;
    right: -15px;
    //height: 70px;
    top: -70px;
    z-index: -1;
    bottom: 0;
  }

  .n-link{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);

    &:hover{
      text-decoration: none;
    }
  }

  .wrap{
    display: flex;
    align-items: center;

    span{
      margin-left: 4px;
      font-size: 12px;
      color: #0c86d8;
      font-weight: 500;
    }
  }

  .back-row {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      transform: rotate(180deg);
    }
  }
  h1 {
    font-size: 18px;
    color: #1c1c1c;
    font-weight: 500;
    margin: 0;
  }
}
</style>
