<template>
  <div class="c-mobile-appbar">
    <div class="appbar-links">
      <n-link :class="routeName === 'mobile.transactions' ? 'appbar-link active' : 'appbar-link'" :to="{name:'mobile.transactions'}">
        <div class="icon"><img :src="imgTransactions" alt=""></div>
        <t name="1.7.1" />
      </n-link>
      <n-link :class="routeName === 'mobile.index' ? 'appbar-link active' : 'appbar-link'" :to="{name:'mobile.index'}">
        <div class="icon"> <img :src="imgHome" alt=""></div>
        <t name="1.6.1" />
      </n-link>
      <n-link :class="routeName === 'mobile.profile' ? 'appbar-link active' : 'appbar-link'" :to="{name:'mobile.profile'}">
        <div class="icon"><img :src="imgUser" alt=""></div>
        <t name="1.8.1" />
      </n-link>
    </div>
  </div>
</template>

<script>
import imgTransactions from 'assets/mobile-appbar/mobile-transactions.svg'
import imgUser from 'assets/mobile-appbar/mobile-user.svg'
import imgHome from 'assets/mobile-appbar/mobile-home.svg'

export default {
  name: 'CMobileAppbar',
  data() {
    return {
      imgHome,
      imgUser,
      imgTransactions,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
  },
  watch: {},
  created() {
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.c-mobile-appbar {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 40px;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #F9F9F9;

  @media screen and (max-width: 600px) {
    bottom: 0;
    padding-bottom: calc(env(safe-area-inset-bottom) - 15px);
    background-color: transparent;
    background-image: linear-gradient(232deg, #0b85d7 0%, #2bbbdb 100%, #2bbbdb 100%);
  }


  .appbar-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    min-height: 75px;

    .appbar-link {
      font-size: 12px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      justify-content: center;
      align-items: center;
      opacity: .6;
      text-decoration: none;
      &:not(:last-child){
        &::before{
          content: '';
          opacity: .5;
          border-right: 1px solid #fff;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
      &.active {
      opacity: 1;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
